// This file is responsible for setting up import shims on dev sites.
// It's a global script that is executed before the impport maps so no import() statements can be present here

type ImportMap = {
    imports: Record<string, string>,
    scopes?: Record<string, Record<string, string>>
};

function init() {
    const bConsole =  window['console'];
    const baseCdnUrl = document.querySelector<HTMLMetaElement>('meta[name="o365-cdn-base-url"]')?.content;
    let dependenciesFingerPrint = 0;
    if (!dependenciesFingerPrint) {
        bConsole.warn('No tag helper found for app dependencies fingerprints, will use random number instead');
        dependenciesFingerPrint = Math.round((Math.random() * 10_000));
    }
    const staticScriptsBaseUrl = `${window.location.origin}/nt/scripts/`;
    let importMapCheckDone = false;

    (window as any).esmsInitOptions = {
        shimMode: true,
        resolve: async (pId: string, pParentUrl: string, pResolve: any) => {
            if (!importMapCheckDone) {
                validateInitialMaps();                
            }

            if (checkedOutLibraries.has(pId)) {
                const result = await customLibraryResolve(pId, pParentUrl);
                return result;
            } else {
                return pResolve(pId, pParentUrl);
            }
        },
        // fetch: (pUrl: string, pOptions: any) => {
        //     return fetch(pUrl, pOptions)
        // },
        mapOverrides: true,
    }
    

    const checkedOutLibraries = new Set<string>();
    const importMapsCache = new Map<string, string>();

    // checkedOutLibraries.add('o365-dataobject')

    function getImportMapsNode() {
        return document.querySelector('script[type="importmap-shim"]');
    }

    function validateInitialMaps() {
        importMapCheckDone = true;
        const maps = (window as any).importShim.getImportMap();
        if (maps == null || maps.imports == null || !Object.keys(maps.imports).length) {
            const importMaps = JSON.parse(getImportMapsNode()!.innerHTML);
            (window as any).importShim.addImportMap(importMaps);
        }
    }

    async function customLibraryResolve(pId: string, _pParentUrl: string) {
        const indexUrl = await getAppCacheModuleIndexPromise(pId);
        return indexUrl;
    }

    const appCacheModuleIndexPromises = new Map<string, Promise<string | undefined>>();

    function getAppCacheModuleIndexPromise(pAppId: string) {
        if (appCacheModuleIndexPromises.has(pAppId)) {
            return appCacheModuleIndexPromises.get(pAppId)!;
        }
        const promise = getAppCacheModuleIndex(pAppId);
        appCacheModuleIndexPromises.set(pAppId, promise);
        return promise;
    }

    async function getAppCacheModuleIndex(pAppId: string) {
        if (!importMapsCache.has(pAppId)) {
            const result = await fetch(`/api/staticfiles/import-map/appfiles/${pAppId}.json?fingerprint=${dependenciesFingerPrint}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .catch(() => ({ imports: {} }));
            transformMaps(result, pAppId);

            const loadedMaps = (window as any).importShim.getImportMap();
            const newMaps = {
                imports: {...loadedMaps.imports, ...result.imports},
                scopes: { ...loadedMaps.scopes, ...result.scopes}
            };

            (window as any).importShim.addImportMap(newMaps);

            importMapsCache.set(pAppId, result.imports[pAppId]);
        }

        return importMapsCache.get(pAppId);
    }

    function transformMaps(pMap: ImportMap, pModuleId: string) {
        // '/nt/scripts/apps/o365-data-export/'
        if (pMap.scopes == null) {
            pMap.scopes = {};
        };

        let indexUrl = '';
        for (const key in pMap.imports) {
            if (key.endsWith('index.ts') || key.endsWith('index.js') ) {
                indexUrl = pMap.imports[key];
                delete pMap.imports[key];
                break;
            }
        }

        const copy = {...pMap.imports};
        const moduleScope = '/nt/scripts/apps/' + pModuleId + '/'
        pMap.scopes[moduleScope] = {};
        pMap.imports = {
            [pModuleId]: indexUrl,
        };
        for (const entry of Object.entries(copy)) {
            pMap.scopes[moduleScope][moduleScope + entry[0]] = entry[1];
        }
    }

    function getModuleScopeFromUrl(pUrl: string) {
        if (pUrl.startsWith(staticScriptsBaseUrl)) {
            if (pUrl.startsWith(staticScriptsBaseUrl + 'site')) {
                return 'site';
            } else {
                return pUrl.split('/').at(-2);
            }
        } else {
            return undefined;
        }
    }

    // --- IndexDB ---

    let dbPromise: Promise<IDBDatabase | undefined> | undefined; 
    function getDb() {
        if (dbPromise) {
            return dbPromise;
        }
        const DBOpenRequest = indexedDB.open('o365-dev-modules-shims', 1);
        let db: IDBDatabase | undefined = undefined;

        let resovleDbOpen = (_pDb?: IDBDatabase) => {};
        dbPromise = new Promise<IDBDatabase | undefined>((res) => {
            resovleDbOpen = res;
        });

        DBOpenRequest.onerror = () => {
            bConsole.error('Failed to open database for library overrides on import maps')
            resovleDbOpen();
        };

        DBOpenRequest.onsuccess = () => {
            db = DBOpenRequest.result;
            resovleDbOpen(db);
        };

        // DBOpenRequest.onupgradeneeded = () => {
        //     db = DBOpenRequest.result;
        // };

        return dbPromise;
    }

    async function doTest() {
        const db = await getDb();
        if (db == null) { return; }

        db.transaction('', 'readonly').objectStore('')
    }

}

init();